
import {inject} from "@angular/core";
import {CanActivateFn, CanMatchFn, Router} from "@angular/router";
import { LogoutService } from "@features/auth/application/logout/logout.service";
import { loginType } from "@features/auth/domain/login-type";
import {AuthStore} from "@features/auth/framework/services/auth-store.service";
import { UserApiService } from "@features/users/infrastructure/services/user-api/user-api.service";
import { TranslocoService } from "@jsverse/transloco";
import { SwalGeneratorService } from "@shared/infrastructure/services/swal-generator.service";

export const loggedIn: CanMatchFn = () => {
  const authStore = inject(AuthStore);
  const router = inject(Router);
  return authStore.hasTokens() || router.parseUrl("/guest/auth");
};

export function loggedInAs(type: loginType): CanMatchFn {
  return () => {
    const authStore = inject(AuthStore);
    const router = inject(Router);
    const currentLoginType = authStore.snapshotOnly(state => state.loginType);

    return type === currentLoginType || router.parseUrl(`/${currentLoginType}`);
  }
}

export const loggedOut: CanMatchFn = async () => {
  const authStore = inject(AuthStore);
  console.log(`logget out: ${!authStore.hasTokens()}`);

  return !authStore.hasTokens();
};

export const isAdmin: CanActivateFn = async () => {
  const router = inject(Router);
  const logoutAction = inject(LogoutService)
  const userApi = inject(UserApiService);
  const translocoService = inject(TranslocoService);
  const swalService = inject(SwalGeneratorService);

  const me = await userApi.getMyData();
  const isAdmin = me.role === 'ADMIN'

  if (isAdmin) {
    return true;
  }

  swalService.swalError(
    translocoService.translate('GENERIC.swal.userNoPermissions')
  ).then();
  
  logoutAction.run();
  router.navigate(['admin/auth']);
  return false;
};
